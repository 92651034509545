<template>
  <div class="max-w-2xl mx-auto p-6 border rounded-lg shadow-md">
<!--    <div class="question-palette">-->
<!--    <button-->
<!--      v-for="(question, index) in totalQuestions"-->
<!--      :key="index"-->
<!--      :class="{ 'attended': attendedQuestions.includes(index), 'not-attended': !attendedQuestions.includes(index) }"-->
<!--    >-->
<!--      {{ index + 1 }}-->
<!--    </button>-->
<!--  </div>-->
   <!-- Progress Bar -->
    <div>
        <ProgressBar :answered="attendedQnsCount" :total="total"></ProgressBar>
    </div>
    <!-- Question -->
    <h3 class="text-lg font-semibold my-4">{{ question }}</h3>

    <!-- Choices -->
    <div v-if="choices.length > 0">
      <div v-for="choice in choices" :key="choice.id" class="mb-2">
        <label class="flex items-center space-x-2 cursor-pointer">
          <input
            type="checkbox"
            :value="choice.id"
            :checked="selectedAnswer === choice.id"
            @click="setSelectedAnswer(choice.id)"
            class="form-checkbox h-5 w-5"
          >
          <span class="ml-2">{{ choice.text }}</span>
        </label>
      </div>
    </div>

    <p v-else>No choices available</p>

<!--    <p>Selected Answer: {{ selectedAnswer }}</p>-->

    <div class="mt-6 flex justify-between">
        <div class="btn-group">
        <btn text="Next"
                     @click="nextQuestion()" :loading="loading" loading-text="Loading..." v-if="!isLastQuestion"></btn>
            <btn text="Submit"
                     @click="submitExam()" :loading="loading" loading-text="Submitting..." v-if="isLastQuestion"></btn>
            </div>
    </div>
  </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import ProgressBar from './ProgressBar';
import store from '@/store';

export default {
    name: 'InterviewSession',
    components: {
        ProgressBar
    },
    data () {
        return {
            loading: false,
            question: null,
            choices: [],
            attendedQuestions: [],
            totalQuestions: [],
            selectedAnswer: null,
            qn_time: '',
            attendedQnsCount: 0,
            balanceQnsCount: 0,
            total: 0
        };
    },
    computed: {
        progress () {
            return (this.attendedQnsCount / (this.attendedQnsCount + this.balanceQnsCount)) * 100;
        },
        isLastQuestion () {
            return this.balanceQnsCount === 0;
        }
    },
    methods: {
        setSelectedAnswer (id) {
            console.log('User selected:', id);
            this.selectedAnswer = id;
        },
        async fetchQuestion () {
            const interview = store.getters.interview;
            const candidate = store.getters.candidate;
            const response = await axios.form(urls.mcq_interview.interview.mcqAttend, { interview_id: interview, candidate_id: candidate, answer: this.selectedAnswer, question: this.question_id, qn_time: this.qn_time });
            const result = response.data;
            if (result.message) {
                this.$notify(result.message, 'Warning', {
                    type: 'warning'
                });
                // this.$router.push('/mcq-interview/landing-page/');
            }
            if (result.success) {
                const data = response.data.data;
                this.question = data.question;
                this.question_id = data.question_id;
                // Convert `choices` from object to array for Vue reactivity
                this.choices = Object.entries(data.choices).map(([id, text]) => ({
                    id: parseInt(id),
                    text
                }));
                this.attendedQnsCount = data.attended_qns_count;
                this.balanceQnsCount = data.balance_qns_count;
                this.attendedQuestions = data.attended_qns;
                this.totalQuestions = data.total_qns;
                this.qn_time = data.qn_fetched_at;
                this.selectedAnswer = null;
                this.total = data.total_qns_count;
            } else {
                const errors = response.data.errors;
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage = '';
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
                this.loading = false;
            }
            this.loading = false;
        },
        nextQuestion () {
            if (this.selectedAnswer) {
                this.loading = true;
                this.attendedQnsCount++;
                this.balanceQnsCount--;
                this.fetchQuestion();
            }
        },
        async submitExam () {
            const interview = store.getters.interview;
            const candidate = store.getters.candidate;
            this.loading = true;
            const response = await axios.form(urls.mcq_interview.interview.submit, { interview_id: interview, candidate_id: candidate });
            const result = response.data;
            if (result.success) {
                this.$notify(' Submitted Successfully', 'Success', { type: 'success' });
                this.loading = false;
                this.$router.push('/mcq-interview/submission-confirmation/');
            } else {
                const errors = response.data.errors;
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage = '';
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.$notify(errorMessage, 'Information', { type: 'warning' });
                    }
                }
                this.loading = false;
            }
        }
    },
    mounted () {
        this.fetchQuestion();
    },
    watch: {
        selectedAnswer (newValue) {
            console.log('Selected Answer updated:', newValue);
        }
    }
};
</script>

<style scoped>
  .form-radio {
    accent-color: blue;
  }

</style>
