<template>
  <div>
    <div style="width: 100%; background-color: white; border: 1px solid #ccc; border-radius: 12px; height: 24px; position: relative; overflow: hidden;">
      <div
        style="background-color: rgb(38,167,224); height: 100%; transition: width 0.3s ease;"
        :style="{ width: progress + '%' }"
      ></div>
    </div>
    <div style="font-size: 14px; font-weight: bold; color: #333; text-align: left; margin-top: 4px;">
      {{ answered }} / {{ total }} Answered ({{ progress.toFixed(1) }}%)
    </div>
  </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        answered: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        }
    },
    computed: {
        progress () {
            return this.total > 0 ? (this.answered / this.total) * 100 : 0;
        }
    }
};
</script>
